import React, { useState } from 'react';
import StyledButton from '../../../shared/StyledButton';
import ArrowGallery from '../../../images/svgs/ArrowGallery';

const Form = () => {
  //   const { register, handleSubmit, errors } = useForm();
  //   const [formSubmitted, setFormSubmitted] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  function validateForm() {
    return name.length > 0 && email.length > 0 && message.length > 0;
  }

  return (
    <React.Fragment>
      <form name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
        <div className="form__name-email">
          <div className="input__container">
            <label>Name</label>
            <input
              type="text"
              name="name"
              placeholder="What's your name?"
              value={name}
              onChange={e => setName(e.target.value)} />
          </div>
          <div className="input__container">
            <label>Email</label>
            <input 
              type="email" 
              name="email"
              placeholder="What's your email?"
              value={email}
              onChange={e => setEmail(e.target.value)} />
          </div>
        </div>
        <div className="input__container">
          <label>Message</label>
          <textarea
            name="message"
            rows="10"
            placeholder="How can we help you?"
            value={message}
            onChange={e => setMessage(e.target.value)}></textarea>
        </div>
        <p>
          <StyledButton borderColor="black" svg type="submit" disabled={!validateForm()}>
            Send Message
            <ArrowGallery direction="right" />
          </StyledButton>
        </p>
      </form>
    </React.Fragment>
  );
};

export default Form;
