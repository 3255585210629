import React from 'react';
import { withPreview } from 'gatsby-source-prismic';
import { graphql } from 'gatsby';
import { color } from '../shared/styles';
import HeroSection from '../components/HeroSection';
import Form from '../components/globals/Form';
import Layout from '../components/layout';

export const data = graphql`
  {
    prismicContactForm {
      dataRaw
    }
    prismicContactInformation {
      data {
        contact_information_section {
          section_text {
            html
            text
          }
        }
      }
    }
  }
`;

const contact = ({ data }) => {
  const {
    prismicContactForm: {
      dataRaw: { contact: header, subheader: subheader, hero_image: image },
    },
  } = data;

  return (
    <Layout bgColor={color.mainBg}>
      <HeroSection
        bg={image.url}
        header={`<h1>${header[0].text}</h1>`}
        subheader={`<p>${subheader[0].text}</p>`}
        page
        contact
      />
      <Form contactPage cms={data.prismicContactInformation.data.contact_information_section} />
    </Layout>
  );
};

export default withPreview(contact);
