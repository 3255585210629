import React from 'react';
import styled from 'styled-components';
import { pageMargins, typography } from '../../../shared/styles';
import Form from './Form';
import Contact from '../Contact';

const StyledContactForm = styled.div`
  width: 100%;
  height: 40rem;
  ${pageMargins}
  margin-top: 6rem;
  & .contact__content {
    display: flex;
    justify-content: space-between;
  }
  & .contact__section {
    margin: 0 !important;
  }
  & h2 {
    font-size: ${typography.size.l1};
  }

  & form {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding-right: 1.875rem;
    & label {
      font-family: ${typography.type.secondary};
      font-size: ${typography.size.s1};
      font-weight: ${typography.weight.bold};
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      letter-spacing: 0.1rem;
    }
    & input,
    textarea {
      padding: 0 0.7rem;
      font-family: ${typography.type.secondary};
      font-size: ${typography.size.s1};
      font-weight: ${typography.weight.regular};
      border-radius: 0px;
      border: 1px solid rgba(0, 0, 0, 0);
    }
    & input {
      height: 3.625rem;
      padding: 0 1rem;
    }

    & textarea {
      padding: 1rem;
    }

    & .form__name-email {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      & .input__container {
        width: 45%;
      }
    }
    & .input__container {
      display: flex;
      flex-direction: column;
    }
    button {
      margin-bottom: 4rem;
      margin-top: 2rem;
    }
  }

  .contact__section:nth-child(1) > h6 {
    margin-top: -0.2rem;
  }
  @media only screen and (max-width: 640px) {
    height: unset;
    & .contact__content {
      flex-direction: column-reverse;
    }
    .contact__section:nth-child(1) > h6 {
      margin-top: 0rem;
      margin-right: 4rem;
    }

    form {
      width: 100%;
      padding-right: 0;
      & .form__name-email {
        flex-direction: column;
        margin-top: 2rem;
        & .input__container {
          width: 100%;
          margin: 0.6rem 0;
        }
      }
    }
  }
`;

const ContactForm = ({ cms, contactPage }) => {
  console.log('cms', cms);
  return (
    <StyledContactForm>
      {!contactPage ? <h2>Work With Us</h2> : ''}
      <div className="contact__content">
        <Form />
        <Contact flexDirection="column" form cms={cms} />
      </div>
    </StyledContactForm>
  );
};

export default ContactForm;
